// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-jsx": () => import("./../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-pages-blogs-jsx": () => import("./../src/pages/blogs.jsx" /* webpackChunkName: "component---src-pages-blogs-jsx" */),
  "component---src-templates-blog-details-jsx": () => import("./../src/templates/blog-details.jsx" /* webpackChunkName: "component---src-templates-blog-details-jsx" */),
  "component---src-templates-disease-details-jsx": () => import("./../src/templates/disease-details.jsx" /* webpackChunkName: "component---src-templates-disease-details-jsx" */),
  "component---src-templates-category-jsx": () => import("./../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-appointment-jsx": () => import("./../src/pages/appointment.jsx" /* webpackChunkName: "component---src-pages-appointment-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-diseases-jsx": () => import("./../src/pages/diseases.jsx" /* webpackChunkName: "component---src-pages-diseases-jsx" */),
  "component---src-pages-faqs-jsx": () => import("./../src/pages/faqs.jsx" /* webpackChunkName: "component---src-pages-faqs-jsx" */),
  "component---src-pages-form-page-jsx": () => import("./../src/pages/form-page.jsx" /* webpackChunkName: "component---src-pages-form-page-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-medicines-jsx": () => import("./../src/pages/medicines.jsx" /* webpackChunkName: "component---src-pages-medicines-jsx" */)
}

